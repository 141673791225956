.rdt_Table_wrapper {
  & > div {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
}
.rdt_Table {
  background-color: transparent !important;
  .rdt_TableHeadRow {
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 6px;
    .rdt_TableCol {
      font-size: 18px;
      color: #333333;
      font-style: normal;
      font-weight: 500;
      span {
        font-size: 12px;
      }
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      transition: all ease 600ms;
      &:not(:last-of-type) {
        border-bottom-color: rgba(238, 238, 238, 1);
      }
      &:hover {
        background-color: #efefef;
      }
      .rdt_TableCell {
        font-size: 16px;
        color: #333333;
        font-style: normal;
        font-weight: 500;
        & > div:first-child {
          white-space: unset;
          overflow: unset;
        }
      }
    }
    .table__input {
      background: #ffffff;
      border: 1px solid #e9eaeb;
      border-radius: 10px;
      padding: 5px 15px;
      outline: none;
    }
  }
  input[type="checkbox"] {
    display: block;
    &:after {
      line-height: 1.5em;
      content: "";
      display: inline-block;
      width: 23px;
      height: 23px;
      margin-top: -4px;
      margin-left: -4px;
      border: 1px solid #1f1b5c;
      background-color: #fff;
      border-radius: 4px;
    }
    &:checked {
      &:after {
        background: url("../images/fiCheck.svg") no-repeat center;
        background-size: 18px 18px;
      }
    }
    &:indeterminate {
      &::after {
        content: "—";
        position: absolute;
        text-align: center;
        line-height: 1;
        color: #fff;
      }
    }
    &:checked,
    &:indeterminate {
      &:after {
        width: 23px;
        height: 23px;
        border-radius: 4px;
        background-color: #1f1b5c;
      }
    }
  }
}
.rdt_Pagination {
  background-color: transparent !important;
  border-top: 0 !important;
  justify-content: center !important;
  margin-top: 35px;
}
.table__status {
  .table__menu__dropdown {
    position: absolute;
    right: 0;
    top: -18px;
    .dropdown-menu {
      right: 0;
      top: 18px;
      border: 0;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      .dropdown-item {
        &:active {
          background-color: #b03d80;
        }
      }
    }
  }
}

.tms {
  &__budget {
    &__table {
      background-color: #fff;
      border-radius: 10px 10px 0px 0px;
      input[type="checkbox"] {
        display: inline-block;
        &:after {
          line-height: 1.5em;
          content: "";
          display: inline-block;
          width: 23px;
          height: 23px;
          margin-top: -3px;
          margin-left: -1px;
          border: 1px solid #1f1b5c;
          background-color: #fff;
          border-radius: 4px;
        }
        &:checked {
          &:after {
            background: url("../images/fiCheck.svg") no-repeat center;
            background-size: 18px 18px;
            width: 23px;
            height: 23px;
            border-radius: 4px;
            border-color: #fff;
            background-color: #1f1b5c;
          }
        }
      }
      & > :not(:first-child) {
        border-top: 6px solid #efefef;
      }
      &__head {
        th {
          font-size: 18px;
          color: #333333;
          font-style: normal;
          font-weight: 500;
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
      &__body {
        tr {
          &:not(:last-child) {
            border-bottom: 1px solid #eee;
          }
          &:hover {
            background-color: #efefef;
          }
          td {
            font-size: 16px;
            color: #333333;
            font-style: normal;
            font-weight: 500;
            border-bottom: 0;
            input[type="text"] {
              border: 1px solid #623594;
              border-radius: 15px;
              padding: 1px 10px;
              outline: none;
              box-shadow: unset;
              &:focus,
              &:focus-visible {
                border: 1px solid #b03d80;
              }
              &:disabled {
                border: 1px solid #999;
              }
            }
          }
        }
      }
      &.download__report {
        vertical-align: middle;
        .tms__react__select {
          .css-rselect__control {
            border: 1px solid #e9eaeb;
            margin-left: 15px;
            .css-rselect__value-container {
              height: 37px;
            }
          }
        }
        thead {
          vertical-align: middle;
        }
        tbody {
          td {
            height: 56px;
            font-weight: 500;
            .download__button {
              color: #cdcdcd;
              transition: all ease 0.2s;
              &:hover {
                color: #b03d80;
              }
            }
          }
        }
      }
    }
  }
}
