.custom_form_control {
  margin-bottom: 20px;
  .form-label {
    color: rgba(153, 153, 153, 1);
    font-weight: 600;
  }
  .form-control {
    &.search__input {
      padding: 12px 10px 12px 55px;
    }
    border-radius: 20px;
    background: #fff;
    border: 1px solid #fff;
    padding: 11px 20px;
    box-shadow: unset !important;
    &:focus {
      border: 1px solid #2c1e68;
    }
  }
  .react-date-picker {
    position: relative;
    .react-datepicker-wrapper {
      & ~ svg {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        stroke: #999999;
      }
    }
    .react-datepicker__day--selected {
      background-color: #2c1e68;
    }
  }
  .form-check,
  &.form-check {
    padding-left: 0;
  }
  input[type="radio"],
  input[type="checkbox"] {
    + {
      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 35px;
        margin-right: 15px;
        font-size: 16px;
        &:before {
          content: "";
          display: block;
          width: 23px;
          height: 23px;
          margin-right: 14px;
          position: absolute;
          top: 1px;
          left: 0;
          border: 1px solid #1f1b5c;
          background-color: #fff;
        }
      }
    }
    display: none;
    &:checked {
      + {
        label {
          &:after {
            content: "";
            display: block;
            position: absolute;
            background-color: #1f1b5c !important;
            top: 1px;
            left: 0;
          }
        }
      }
    }
  }

  input[type="checkbox"] {
    + {
      label {
        &:before {
          border-radius: 4px;
        }
      }
    }
    &:checked {
      + {
        label {
          &:after {
            background: url("../images/fiCheck.svg") no-repeat center;
            background-size: 18px 18px;
            width: 23px;
            height: 23px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  input[type="radio"] {
    + {
      label {
        &:before {
          border-radius: 50%;
        }
      }
    }
    &:checked {
      + {
        label {
          &:after {
            top: 50%;
            left: 5px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .input-group {
    .number-incrementer {
      padding: 0;
      border: 0;
      flex-direction: column;
      background-color: #fff;
      overflow: hidden;
      border-radius: 0 20px 20px 0;
      border-left: 2px solid #cfcfcf;
      .btn {
        padding: 0;
        box-shadow: unset !important;
        background-color: transparent;
        outline: 0 !important;
        border: 0;
        border-radius: 0;
        height: 24px;
        width: 39px;
        transition: 300ms all ease;
        svg {
          stroke: #999;
        }
        &:hover {
          background-color: #999999;
          svg {
            stroke: #fff;
          }
        }
      }
      &:after {
        position: absolute;
        content: "";
        height: 1px;
        width: 40px;
        background: #cfcfcf;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .react-datepicker-popper{
    z-index: 10;
  }
}

.btn {
  &.btn {
    &-primary {
      background-color: #b03d80;
      border-color: #b03d80;
    }
    &-warning {
      background-color: #f68b1e;
      border-color: #f68b1e;
    }
    &-outline {
      &-warning {
        border-color: #f68b1e;
        color: #f68b1e;
        &:hover {
          background-color: #f68b1e;
          color: #fff;
        }
      }
    }
    &-rounded {
      font-size: 18px;
      font-weight: 700;
      padding: 10px 12px;
      border-radius: 30px;
      min-width: 215px;
    }
    &-gradient {
      background: linear-gradient(45deg, #221e67, #5e1f70 20%, #b54081);
      color: #fff;
      &-success {
        background: linear-gradient(90deg, #417535 46.45%, #87b34c 84.62%);
        color: #fff;
      }
    }
    &-sm-rounded {
      border-radius: 10px;
      padding: 10px 15px;
      font-size: 18px;
    }
    &-transparent {
      padding: 10px 12px;
      box-shadow: unset !important;
    }
    &-circle {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      padding: 0;
      &.btn {
        &-pink {
          background-color: #b03d80;
        }
      }
      svg {
        stroke: #fff;
      }
    }
    &-save {
      border-radius: 10px;
      color: #fff;
      &-active {
        background-color: #87b34c;
      }
      &-inactive {
        background-color: #c4c4c4;
      }
    }
    &-reject {
      background-color: #eb5757;
      color: #fff;
    }
  }
}

.quill {
  .ql-toolbar {
    background: #c4c4c4;
  }
  .ql-container {
    background-color: #fff;
    min-height: 165px;
    .ql-editor {
      min-height: 160px;
    }
  }
}

/* Rounded sliders */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input {
    &:checked {
      + {
        .slider {
          background-color: #87b34c;
          &:before {
            transform: translateX(20px);
          }
        }
      }
    }
    &:focus {
      + {
        .slider {
          box-shadow: 0 0 1px #2196f3;
        }
      }
    }
  }
  .slider.round {
    border-radius: 34px;
    &:before {
      border-radius: 50%;
    }
  }
}
