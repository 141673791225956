.sidebar__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
  overflow-y: auto;
  .profile__container {
    margin-top: calc(40px + 74px);
    transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 60ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile__image {
      height: 135px;
      width: 135px;
      margin-bottom: 15px;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 135px;
        width: 135px;
        object-fit: cover;
      }
    }
    .profile__name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #2c1e68;
      padding-bottom: 5px;
    }
    .profile__designation {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #4b4b4b;
    }
  }
  .menu__container {
    padding: 0 20px;
    margin-top: 40px;
    .menu__item {   
        margin-bottom: 20px;
      > svg {
        width: 19px !important;
        height: 19px !important;
        stroke: #b03d80;
      }
      .menu__title {
        padding-left: 10px;
        font-size: 15px;
        color: #b03d80;
        font-weight: 500;
      }
    }
  }
}
