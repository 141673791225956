@import "../css/dataTable.scss";
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Quicksand-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Quicksand-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Quicksand-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Quicksand-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Quicksand-Bold.woff2") format("woff2");
}

$primary-purple: #652d86;
$light-purple: #f7e1ff;
$ncell-pink: #b63f81;
$gray: #a192aa;
$bg-light: #f8f8ff;

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  font-weight: normal;
  background-color: #efefef;
}

.text {
  &-primary {
    color: #2c1e68 !important;
  }
  &-secondary {
    color: #666666 !important;
  }
  &-light {
    color: #999999 !important;
  }
  &-pink {
    color: #b03d80 !important;
  }
  &-pink-dark {
    color: #733269 !important;
  }
  &-dark {
    color: #4b4b4b !important;
  }
  &-warning {
    color: #f68b1e !important;
  }
  &-success {
    color: #87b34c !important;
  }
  &-danger {
    color: #f05323 !important;
  }
  &-reject {
    color: #eb5757 !important;
  }
}
a {
  text-decoration: none;
  color: #000;
}

.main__panel {
  margin-top: 40px;
  padding-left: calc(175px + 60px);
  padding-right: 60px;
  min-height: calc(100vh - 194px);
}

.card {
  background: #ffffff;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: 0;
  .card-body {
    .session__wrapper {
      border-radius: 3px;
      .session__container {
        position: relative;
        .session_action {
          position: absolute;
          top: 5px;
          right: 5px;
        }
        &.session__active {
          background-color: #eeeeee;
          border-radius: 3px;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
          .session_action {
            display: block !important;
            span {
              &:hover {
                cursor: pointer;
              }
            }
          }
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: 0;
            background-color: #dcdcdc;
          }
        }
      }
    }
    &.session_container{
      max-height: 200px;
      overflow-y: auto;
    }
  }
}

svg {
  min-width: 21px !important;
  min-height: 21px !important;
}

.header__wrapper {
  border-bottom: 1px solid rgba(196, 196, 196, 1);
  padding-bottom: 12px;
}

.rc-rate-star {
  font-size: 25px;
}

.show__more {
  &__filter {
    span {
      color: #2c1e68;
    }
    svg {
      stroke: #778583;
    }
  }
}

.section {
  border-bottom: 1px solid #c4c4c4;
  &__filter {
  }
}

.dropdown__rounded {
  .btn-transparent {
    width: 100%;
    background-color: white;
    border: 1px solid rgba(44, 30, 104, 1);
    border-radius: 20px;
    box-shadow: unset !important;
    padding: 10px 20px;
  }
  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #e9eaeb;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    .dropdown-item {
      padding: 8px 12px;
      &.active {
        background-color: rgba(44, 30, 104, 1);
      }
    }
  }
}

.tms {
  &__search {
    position: relative;
    .search__input {
      background: rgba(255, 255, 255, 0.88);
      border-radius: 20px;
      padding: 12px 10px 12px 55px;
      border: 0;
      outline: 0;
      box-shadow: unset !important;
      &::placeholder {
        color: #666666;
      }
    }
    .search__icon {
      position: absolute;
      left: 23px;
      top: 50%;
      transform: translateY(-50%);
      stroke: #666666;
    }
  }
  &__custom__tab {
    .nav-tabs {
      border-bottom-color: #c4c4c4;
      .nav-item {
        .nav-link {
          display: flex;
          position: relative;
          color: #999999;
          font-weight: 600;
          font-size: 16px;
          &.active {
            color: #2c1e68;
            &:after {
              position: absolute;
              content: "";
              bottom: 0;
              left: 0;
              height: 5px;
              width: 100%;
              border-radius: 5px;
              background: linear-gradient(45deg, #221e67, #5e1f70 20%, #b54081);
            }
            svg {
              stroke: #b03d80;
            }
          }
          background-color: transparent;
          border: 0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__react__select {
    & .css-rselect__control {
      border-color: #fff;
      border-radius: 20px;
      .css-rselect__value-container {
        padding: 0 10px;
        height: 46px;
        .css {
          &-rselect__multi-value {
            margin: 0;
            background-color: #efefef;
            border-radius: 15px;
            padding-right: 5px;
            padding-left: 5px;
            display: flex;
            align-items: center;
            &:not(:last-of-type) {
              margin-right: 8px;
            }
            svg {
              min-height: 15px !important;
              min-width: 15px !important;
              fill: #eb5757;
            }
            .css-rselect__multi-value__label {
              padding-right: 8px;
            }
          }
          &-rselect__multi-value__remove {
            border-radius: 50%;
            border: 2px solid #eb5757;
            height: 19px;
            width: 19px;
            justify-content: center;
            &:hover {
              background-color: transparent;
            }
          }
        }
        div[class$="singleValue"],
        div[class$="placeholder"] {
          margin-left: 12px;
        }
        div[class$="Input"] {
          margin: 0 0 0 12px;
          padding: 0;
        }
        &--is-multi {
          min-height: 46px;
          height: auto;
        }
      }
      div[class$="indicatorContainer"] {
        padding-right: 16px;
      }
    }
    .css {
      &-rselect__control--is-focused {
        box-shadow: unset;
        border-color: #2c1e68;
        &:hover {
          border-color: #2c1e68;
        }
      }
      &-rselect__indicator-separator {
        width: 0;
      }
      &-rselect__menu {
        border-color: #e9eaeb;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        .css {
          &-rselect__menu-list {
            padding-top: 8px;
            padding-bottom: 6px;
            div[class$="option"] {
              color: #4b4b4b;
              font-weight: 500;
              font-size: 16px;
              display: flex;
              align-items: center;
              &:hover {
                background-color: #e9ecef;
              }
            }
            .css {
              &-rselect__option {
                background-color: #fff;
                span {
                  padding-left: 10px;
                  display: inline-block;
                  width: 80%;
                }
              }
              &-rselect__option--is-selected {
                background-color: #2c1e68 !important;
                color: #fff !important;
              }
            }
            .css-rselect_radio {
              display: none;
            }
            &.css-rselect__menu-list--is-multi {
              .css-rselect_radio {
                display: inline-block;
                &:after {
                  line-height: 1.5em;
                  content: "";
                  display: inline-block;
                  width: 23px;
                  height: 23px;
                  margin-top: -4px;
                  margin-left: -4px;
                  border: 1px solid #1f1b5c;
                  background-color: #fff;
                  border-radius: 4px;
                }
                &:checked {
                  &:after {
                    background: url("../images/fiCheck.svg") no-repeat center;
                    background-size: 18px 18px;
                    width: 23px;
                    height: 23px;
                    border-radius: 4px;
                    border-color: #fff;
                    background-color: #1f1b5c;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.select_pills {
      .css-rselect__value-container {
        min-height: 46px;
        height: auto;
      }
      .css-rselect__menu-list {
        padding-left: 8px;
        padding-right: 8px;
        .css-rselect__menu-list_innerContainer {
          & > div {
            margin-bottom: 10px;
          }
        }
        div {
          display: inline-block;
          margin-right: 10px;
          .css-rselect__option {
            background-color: #efefef;
            border-radius: 20px;
            margin-right: 7px;
            white-space: nowrap;
            word-break: break-all;
            .css-rselect_radio {
              display: none;
            }
            :not(:last-of-type) {
              margin-bottom: 10px;
            }
          }
        }
        & > div {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
    &.select_hasAdd {
      .css-rselect__menu-list {
        max-height: 0;
        overflow: unset;
        &_innerContainer {
          height: 100%;
          max-height: 150px;
          overflow-y: auto;
          box-shadow: 10px 0px 10px -11px rgba(0, 0, 0, 0.25),
            -10px 0px 10px -11px rgba(0, 0, 0, 0.25);
        }
        .select__addNew {
          background-color: #fff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 555;
          border-radius: 0 0 5px 5px;
          &:hover {
            cursor: pointer;
          }
        }
        .newTopicCard {
          margin-top: 1rem;
          z-index: 555;
          .card-header {
            background-color: #c4c4c4;
            color: #333333;
            font-size: 18px;
            font-weight: 500px;
          }
          .card-body {
            .form-control {
              height: 48px;
              background: #ffffff;
              border: 1px solid #e9eaeb;
              box-sizing: border-box;
              border-radius: 5px;
            }
          }
        }
        .css-rselect__option {
          span {
            padding-left: 0;
          }
          .popoverOnHover-header {
            padding-left: 10px;
          }
        }
      }
    }
  }
  &__modal {
    background: rgba(255, 255, 255, 0.88);
    backdrop-filter: blur(4px);
    .modal-content {
      background: #e9eaeb;
      border: 0;
      box-sizing: border-box;
      border-radius: 10px;
      .modal-header {
        padding: 18px 30px;
        border-bottom: 1px solid #c4c4c4;
        .modal-title {
          font-size: 20px;
          color: #333333;
        }
        .btn-close {
          font-size: 13px;
        }
      }
      .modal-body {
        padding: 25px 30px 18px 30px;
        .modal__detail {
          font-size: 18px;
          .modal__title {
            color: #999999;
            display: inline-block;
            min-width: 200px;
          }
          .modal__value {
            color: #4b4b4b;
            p {
              display: inline;
            }
          }
        }
      }
    }
    .sectioned__modal {
      .section__left {
        width: 50%;
        background: #fff;
        border-radius: 10px 0 0 10px;
      }
      .section__right {
        width: 50%;
        background: #e9eaeb;
        border-radius: 0 10px 10px 0;
      }
    }
    &.modal__rejected {
      .modal-header {
        background-color: #eb5757;
        .btn-close {
          filter: invert(1);
          opacity: 1;
        }
      }
    }
  }
  &__accordion {
    .accordion-item {
      border-radius: 5px 5px 0 0 !important;
      border: 0;
      .accordion-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.13);
        .accordion-button {
          background-color: #dcdcdc;
          border-radius: 5px 5px 0px 0px;
          box-shadow: unset !important;
        }
      }
      .accordion-body {
        border-radius: 0 0 5px 5px;
        .accordion-card {
          box-shadow: unset;
          .card-header {
            color: #333;
            font-weight: bold;
            border-radius: 4px;
          }
          .card-body {
          }
        }
      }
    }
  }
  &__badge {
    border-radius: 20px;
    background-color: #fff !important;
    &.badge {
      &__yes {
        border: 1px solid #87b34c;
        color: #87b34c;
      }
      &__no {
        border: 1px solid #eb5757;
        color: #eb5757;
      }
      &__dark {
        background-color: #e9eaeb !important;
        color: #333333;
      }
    }
  }
  &__dropzone {
    border: 2px dashed #bcbbbb;
    border-radius: 20px;
    padding: 15px;
    text-align: center;
    .upload-icon {
      svg {
        min-width: 32px !important;
        min-height: 32px !important;
      }
    }
    .upload-text {
      font-size: 18px;
    }
    p {
      font-weight: 500;
    }
  }
  &__timepicker {
    position: relative;
    // z-index: 5;
    .timepicker {
      &__input {
        position: relative;
        svg {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
        .time__duration__field {
          padding-left: 45px;
          & + svg {
            left: 15px;
          }
        }
        &.duration__input {
          &:after {
            position: absolute;
            content: "Hr/Min";
            color: #666666;
            right: 10px;
            top: 50%;
            transform: translateY(-40%);
            font-weight: 500;
          }
        }
      }
      &__container {
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        position: absolute;
        padding: 15px;
        top: 135%;
        z-index: 5;
        &:after {
          content: "";
          position: absolute;
          top: -14px;
          left: 30px;
          border-bottom: 14px solid white;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.15));
        }
        .form-control {
          border: 1px solid #e9eaeb;
          border-radius: 10px;
          padding: 0;
          width: 54px;
          height: 48px;
        }
        .btn-meridian {
          background-color: #e9eaeb;
          height: 48px;
          width: 48px;
          box-shadow: unset;
          transition: all ease 300ms;
          &:hover {
            background-color: #b03d80;
            color: #fff;
          }
        }
      }
    }
  }
}

// custom scrollbar for textarea
.custom-scrollbar {
  &::-webkit-scrollbar {
    //width
    width: 8px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    //track
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    //handle
    background-color: #c4c4c4;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb:hover {
    //handle on hover
    background: #b6b6b6;
  }
}
//end of scrollbar styling

.popover {
  max-width: 350px;
  .skillSets_section {
    span {
      &:not(:last-child) {
        margin-right: 10px;
      }
      background-color: #efefef !important;
      color: #4b4b4b;
    }
  }
}


//elearning file list text truncate styling

.eLearningDetail p{
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}