.footer__container {
  background-color: #dcdcdc;
  margin-left: 175px;
  position: relative;
  padding: 28px 0;
  .section__copyright {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #733269;
  }
  .footer__logo {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
