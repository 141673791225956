nav {
  &.bg-ncell {
    background: linear-gradient(45deg, #221e67, #5e1f70 20%, #b54081);
    z-index: 10;
  }
}

.nav__right_section {
  .notification {
    position: relative;
    .notification__icon {
      stroke: #fff;
      font-size: 25px;
    }
    .notification__count {
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: #e00e0e;
      top: -8px;
      right: -4px;
      font-size: 10px;
      font-weight: 400;
      line-height: 1.7;
    }
  }
}

.nav__userDetails__container {
  .dropdown {
    .btn-transparent {
      background-color: transparent;
      outline: 0;
      box-shadow: unset !important;
      min-width: 170px;
      max-width: 170px;
      padding: 19px 0;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        margin-left: 0.255 em;
        vertical-align: 0.255 em;
        right: 15px;
        top: 45%;
        border: 0;
        background: url(../images/chevron-down.svg) no-repeat;
        height: 10px;
        width: 15px;
      }
      .user_profile__picture {
        border-radius: 50%;
      }
      .user__name {
        width: 120px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
      }
    }
    .dropdown-menu{
      &.show {
        &:after {
          content: "";
          position: absolute;
          top: -14px;
          right: 30px;
          border-bottom: 14px solid rgb(255, 255, 255);
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
        }
      }
    }
  }
}
.nav__userDetails__container,
.notification__wrapper{
  .dropdown-menu {
    padding: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background: rgb(255, 255, 255);
    border: 0;
    .dropdown-item {
      padding-top: 8px;
      padding-bottom: 8px;
      &:hover {
        background: #652d86;
        color: #fff;
      }
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
    .switch {
      position: absolute;
      right: 7px;
      top: 11px;
    }
    .dropdown-divider {
      margin: 0;
    }
    left: unset;
    right: 10px;
  }
}
.notification__wrapper{
  .btn-transparent{
      &:after{
        content: unset;
        background: unset;
      }
    }
    .dropdown-menu {
      right: 0;
      width: 475px;
      .dropdown-item{
        &:last-child {
          border-radius: 0;
        }
        &.unread{
          position: relative;
          &:after{
            content: '';
            position: absolute;
            height: 9px;
            width: 9px;
            background-color: #f68b1e;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            border-radius: 50%;
          }
        }
        &.load-more{
          border-radius: 0 0 5px 5px !important;
          &:hover{
            background-color: #b03d80 !important;
          }
        }
      }
      .notification__list{
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
}